import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { HomeFeature } from "../components/Home"
import SEO from "../components/SEO/seo"
import TestimonialIndex from "../components/testimonial"
import {
  CardContainer,
  CommonCard,
  CommonTitle,
  StyledA,
  GroupManageTitle,
  BottomLine,
} from "../components/commonFeatureComp"
import BgImgComp from "../components/bgImgComp"
import { GatsbyImage } from "gatsby-plugin-image"
import bgWeb from "../../images/bg-1.svg"
import bgMobile from "../../images/bg-2.svg"
import groupKit1 from "../../images/group-kit-1.svg"
import groupKit2 from "../../images/group-kit-2.svg"
import groupKit3 from "../../images/group-kit-3.svg"
import groupKit4 from "../../images/group-kit-4.svg"

const H1ImageContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  align-content: center;
`
const H1Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`

const TitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin-top: 10px;
`
const Title = styled.h2`
  font-size: 32px;
  color: #2f394e;
  text-align: center;
  padding: 0 15px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const TitleH1 = styled.h1`
  box-sizing: border-box;
  color: #fff;
  font-size: ${props => (props.fontSize ? props.fontSize : `42px`)};
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const TitleH3 = styled.h3`
  font-size: 24px;
  color: #33475b;
  text-align: start;
  margin: 2rem 0 0.5rem 0;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`
const Bottomline = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 10px;
  background-color: #33cdd1;
  margin: 0 auto;
`
const TitleTextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1000px);
  justify-content: center;
  padding: 15px 40px;
  @media (max-width: 575px) {
    padding: 15px 20px;
  }
`
const GroupManageTitleContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(1000px, 1250px);
  padding: ${props => props.padding || `3rem 30px`};
  justify-content: center;
  @media (max-width: 1024px) {
    grid-template-columns: 92%;
  }
  @media (max-width: 800px) {
    grid-template-columns: auto;
    padding: 0 20px;
    padding-bottom: ${props => props.isRefund && `25px`};
    text-align: center;
  }
`
const TitleText = styled.p`
  font-size: ${props => props.fontSize || `18px`};
  text-align: ${props => props.textAlign || `center`};
  margin: ${props => props.margin || `1.7rem 0 1rem 0`};
  line-height: 1.6;
  color: #3d4d69;
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
const FeaturesCompareContainer = styled.div`
  max-width: 1250px;
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr;
  padding: 30px;
  /* justify-items: center; */
  margin: 0 auto;
  background-color: #fff;
  :nth-of-type(even) {
    background-color: #f8fcfc;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `25px 0` : `25px 10px`)};
    margin: 0 20px;
    grid-template-columns: 1.5fr 1fr 1fr;
  }
`
const FeatureCompareElement = styled.div`
  margin: 0;
  color: #33475b;
  font-size: ${props => (props.title ? `22px` : `16px`)};
  padding: 0 15px;
  display: grid;
  align-content: center;
  justify-content: ${props => props.icon && `center`};
  font-weight: ${props => props.title && `700`};
  @media (max-width: 575px) {
    padding: ${props => (props.title ? `0` : `0 5px`)};
    font-size: ${props => (props.title ? `14px` : `13px`)};
  }
`

const BrandComponent = styled.div`
  display: grid;
  grid-row-gap: 15px;
  padding: 0 20px;
  @media (max-width: 575px) {
    padding: 0 5px;
  }
`
const FreeTrialCapsule = styled.div`
  color: white;
  font-size: 14px;
  background: ${props => (props.freeTrial ? `#4DBB9D` : `#F95663`)};
  border-radius: 17px;
  padding: 8px 20px;
  margin: 0 auto;
  display: grid;
  place-items: center;
  text-align: center;
  @media (max-width: 575px) {
    display: none;
  }
`

const FreeTrialButton = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  background-color: #fff;
  padding: 20px 35px;
  border-radius: 6px;
  border: 1px solid #33cbcf;
  font-size: 18px;
  margin: 5vh auto;
  &:hover {
    color: #fff;
    background-color: #33cbcf;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`
const PlanCardContainer = styled.div`
  display: grid;
  grid-template-columns: 520px 520px;
  grid-column-gap: 40px;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 400px;
    grid-row-gap: 20px;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`
const PlanCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #c2cce1;
  border-radius: 30px;
  grid-row-gap: 35px;
  padding: 35px 0;
  background-color: #fff;
`
const PlanCardTableGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px 25px;
  padding: 0 35px;
`
const PlanCardText = styled.p`
  margin: 0;
  font-size: ${props => (props.title ? `36px` : `16px`)};
  font-weight: ${props => props.title && `700`};
  padding: ${props => props.title && `0 35px`};
`
const TablePaddedContainer = styled.div`
  background-color: #eafafa;
  padding-top: 30px;
`
const ColoredContainer = styled.div`
  display: grid;
  padding: ${props => (props.isProsCons ? `45px 50px` : `30px 40px`)};
  margin: 30px 0;
  border-radius: 10px;
  background-color: ${props => props.bgColor || `#eafafa`};
  @media (max-width: 575px) {
    padding: ${props => (props.isProsCons ? `25px` : `10px 25px`)};
  }
`
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  padding: 30px 0 1.8rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`
const WebView = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`
const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`

const FeatureCompareRow = props => (
  <FeaturesCompareContainer>
    <FeatureCompareElement>{props.featureText}</FeatureCompareElement>
    <FeatureCompareElement icon>
      {props.element.groupboss}
    </FeatureCompareElement>
    <FeatureCompareElement icon>{props.element.groupX}</FeatureCompareElement>
  </FeaturesCompareContainer>
)

const ColorButton = styled.div`
  padding: 16px 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #33cdd1;
  border: 1px solid #33cdd1;
  white-space: nowrap;
  border-radius: 6px;
  font-size: 18px;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #33cdd1;
    background-color: #fff;
  }
`
const ResponsiveImageWeb = styled.img`
  @media (max-width: 575px) {
    display: none;
  }
`
const ResponsiveImageMobile = styled.img`
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
`
const CustomList = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: 25px 15px;
  padding: 25px 0;
  align-items: center;
`
const ColoredDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.bgColor || `#92A2FA`};
`
const ListText = styled.p`
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  margin: 0;
  color: #33475b;
`
export const StyledList = styled.li`
  line-height: 2;
`

export default ({ data, location }) => (
  <>
    <SEO
      title="Groupkit Alternative & Review in 2024"
      description="If you are looking for Groupkit alternative, you are in the right place. Get a comprehensive idea on Groupboss, a good alternative to Groupkit"
      pathname={location.pathname}
    />

    <H1ImageContainer>
      <ResponsiveImageWeb src={bgWeb} alt="home-background" width="100%" />
      <ResponsiveImageMobile
        src={bgMobile}
        alt="home-background mobile"
        width="100%"
      />
      <H1Container>
        <TitleH1>The Best GroupKit Alternative & Review in 2024</TitleH1>
      </H1Container>
    </H1ImageContainer>

    <TitleTextContainer>
      <TitleText>
        GroupKit is a Facebook Group management CRM and automation software with
        different features. They also have a few integrations. If you are
        thinking of collecting leads from Facebook groups, Groupkit can help you
        all the way.
      </TitleText>

      <TitleText>
        But, if you look at the pricing of Groupkit, there are many better
        alternatives to it with less price.
      </TitleText>
      <TitleText>
        If you are looking for the best alternative to Groupkit, you can check
        out Groupboss.
      </TitleText>
    </TitleTextContainer>

    <div
      style={{ display: `grid`, justifyContent: `center`, margin: `20px 0` }}
    >
      <Link
        to="/pricing"
        target="_blank"
        style={{ textDecoration: "none", color: "#33475B", margin: `0 auto` }}
      >
        <ColorButton>Sign Up at Groupboss</ColorButton>
      </Link>
    </div>

    <TitleTextContainer>
      <CommonTitle
        fontSize="32px"
        fontWeight="700"
        margin="4.5rem 0"
        color="#33475B"
        style={{ textAlign: `center` }}
      >
        Why Should You Choose Groupboss over Group Convert?
      </CommonTitle>
      <TitleText>
        Groupboss gives you more flexibility and features than GroupKit for
        generating unlimited leads from Facebook Groups. You will also get more
        advanced features in Groupboss that will help will help you to automate
        the lead collection from your Facebook group.
      </TitleText>

      <TitleH3>
        Let’s have a quick look at the comparison of features between Groupboss
        and Groupkit
      </TitleH3>
    </TitleTextContainer>

    <TablePaddedContainer>
      <FeaturesCompareContainer title style={{ background: `#eafafa` }}>
        <FeatureCompareElement title>
          Features and Service{" "}
        </FeatureCompareElement>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gb_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupboss logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
        <BrandComponent>
          <WebView>
            <GatsbyImage
              image={data.gk_logo.childImageSharp.gatsbyImageData}
              alt="groupleads logo"
            />
          </WebView>
          <MobileView>
            <GatsbyImage
              image={data.gl_logo_mobile.childImageSharp.gatsbyImageData}
              alt="groupleads logo mobile"
              style={{ width: `50px`, margin: `0 auto` }}
            />
          </MobileView>
        </BrandComponent>
      </FeaturesCompareContainer>

      <FeatureCompareRow
        featureText="Starting Price"
        element={{
          groupboss: "$45/Quarter",
          groupX: "$34/Month",
        }}
      />
      <FeatureCompareRow
        featureText="Google sheets Integration"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Dashboard"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />
      <FeatureCompareRow
        featureText="Integration with Email Autoresponders"
        element={{
          groupboss: "25+",
          groupX: "10",
        }}
      />
      <FeatureCompareRow
        featureText="Membership approval"
        element={{
          groupboss: "Unlimited for all plans",
          groupX: "Only 300 for Basic plan",
        }}
      />

      <FeatureCompareRow
        featureText="Zapier Required"
        element={{
          groupboss: "No(Optional)",
          groupX: "Optional",
        }}
      />
      <FeatureCompareRow
        featureText="Custom & Lookalike Audience "
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Download all data from the dashboard in CSV or Excel format
        "
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Chat Support"
        element={{
          groupboss: "For all users",
          groupX: "No",
        }}
      />
      <FeatureCompareRow
        featureText="Support in Email"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Refund Policy"
        element={{ groupboss: "14 Days", groupX: "14 Days" }}
      />

      <FeatureCompareRow
        featureText="Exclusive Support through Zoom/Skype/Gmeet"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.cross.childImageSharp.gatsbyImageData}
              alt="cross"
            />
          ),
        }}
      />

      <FeatureCompareRow
        featureText="Free Trialing"
        element={{
          groupboss: "Available",
          groupX: "Available",
        }}
      />

      <FeatureCompareRow
        featureText="Free set up Call"
        element={{
          groupboss: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
          groupX: (
            <GatsbyImage
              image={data.tick.childImageSharp.gatsbyImageData}
              alt="tick"
            />
          ),
        }}
      />

      <div style={{ display: `grid`, justifyContent: `center` }}>
        <Link
          to="/pricing"
          target="_blank"
          style={{ textDecoration: "none", color: "#33475B" }}
        >
          <FreeTrialButton>Get 7 Days Free Trial at Groupboss</FreeTrialButton>
        </Link>
      </div>
    </TablePaddedContainer>

    <GroupManageTitleContainer>
      <GroupManageTitle>
        Why Groupboss is the best GroupKit alternative:
      </GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        Groupboss is quite similar to Facebook group automation tools like
        GroupKit, with many differences.
      </TitleText>

      <TitleText textAlign="start">
        From the following comparison, you will be able to understand which one
        is the best suit for you between Groupboss and GroupKit.
      </TitleText>
    </GroupManageTitleContainer>

    <div style={{ backgroundColor: `#FDFAF3`, paddingBottom: `2rem` }}>
      <GroupManageTitleContainer>
        <TitleH3>1. Cost-Effective Plan for Users: </TitleH3>
        <TitleText textAlign="start">
          Groupboss has got the best ever affordable plans for the customers. On
          the other hand, the pricing of Groupkit is higher than its
          competitors.
        </TitleText>

        <TitleH3>
          Let’s look at the pricing comparison between Groupboss and Groupkit.
        </TitleH3>
      </GroupManageTitleContainer>

      <PlanCardContainer>
        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gb_logo.childImageSharp.gatsbyImageData}
              alt="groupboss"
            />
          </div>

          <PlanCardText title>
            $45
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / quarter
            </span>
          </PlanCardText>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Quarterly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
            <PlanCardText>No of Membership Approval:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>
          {/* new added end */}
          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $99
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>3</PlanCardText>
            <PlanCardText>No of Membership Approval:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $189
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / year
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Groupboss Yearly (Mega Plan)</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>20</PlanCardText>
            <PlanCardText>No of Membership Approval:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>

        <PlanCard>
          <div style={{ maxWidth: `210px`, paddingLeft: `35px` }}>
            <GatsbyImage
              image={data.gk_logo.childImageSharp.gatsbyImageData}
              alt="gk_logo"
            />
          </div>
          <PlanCardText title>
            $34
            <span style={{ fontSize: `22px`, fontWeight: `400` }}>
              {" "}
              / month
            </span>
          </PlanCardText>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Monthly</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>1</PlanCardText>
            <PlanCardText>No of Membership Approval:</PlanCardText>
            <PlanCardText>300</PlanCardText>
          </PlanCardTableGrid>
          {/* new added end */}
          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>
              $48
              <span style={{ fontSize: `22px`, fontWeight: `400` }}>
                {" "}
                / monthly pro
              </span>
            </PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>Monthly Pro</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
            <PlanCardText>No of Membership Approval:</PlanCardText>
            <PlanCardText>Unlimited</PlanCardText>
          </PlanCardTableGrid>

          <div style={{ borderTop: `1px solid #E8ECF3`, paddingTop: `35px` }}>
            <PlanCardText title>N/A</PlanCardText>
          </div>
          <PlanCardTableGrid>
            <PlanCardText>Type of Plan:</PlanCardText>
            <PlanCardText>One-Time</PlanCardText>
            <PlanCardText>No of Groups:</PlanCardText>
            <PlanCardText>N/A</PlanCardText>
          </PlanCardTableGrid>
        </PlanCard>
      </PlanCardContainer>
    </div>

    <GroupManageTitleContainer style={{ backgroundColor: `#FFF5F7` }}>
      <TitleH3>2. Better Customer Support and User Experience</TitleH3>
      <TitleText textAlign="start">
        Groupboss is always concerned about rendering the best service to the
        customers. Its users are highly satisfied with the support system.
      </TitleText>

      <ImageContainer>
        <WebView>
          <GatsbyImage
            image={data.detailed_tutorials.childImageSharp.gatsbyImageData}
            alt="feedback detail tutorials"
          />
        </WebView>
        <MobileView>
          <GatsbyImage
            image={
              data.detailed_tutorials_mobile.childImageSharp.gatsbyImageData
            }
            alt="feedback detail tutorials"
          />
        </MobileView>
        <TitleText fontSize="16px">
          Source:{" "}
          <span>
            {" "}
            <StyledA
              color="#007AFF"
              textDecoration="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
            >
              Groupboss Chrome Extension
            </StyledA>
          </span>{" "}
        </TitleText>
      </ImageContainer>

      <TitleText textAlign="start">
        On the other hand, there is nothing mentioned about the support system
        on GroupKit’s website. You need to go to the support option, then the
        troubleshooting section. After one more step, you are going to get their
        support email.
      </TitleText>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#EAFAFA` }}>
      <TitleH3>3. Unlimited number of membership approval</TitleH3>
      <TitleText textAlign="start">
        There is no limit to the number of member approval in Groupboss. It
        doesn’t matter whether you are using the yearly or lifetime plan of
        Groupboss, you can approve an <strong>unlimited</strong> number of{" "}
        <strong>members </strong>in your Facebook groups.
      </TitleText>

      <TitleText textAlign="start">
        In the case of Groupkit, you have the access to{" "}
        <strong>1 Facebook group</strong> with a <strong>300</strong> contacts
        limit in the basic monthly plan.
      </TitleText>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#FDFAF3` }}>
      <TitleH3>4. Number of Email Autoresponders in Groupboss</TitleH3>
      <TitleText textAlign="start">
        Groupboss has got integrations with the popular email marketing
        autoresponders. You can collect leads from your Facebook group and send
        them to your favourite email marketing autoresponder with a single
        click.
      </TitleText>
      <TitleText textAlign="start">
        <li>MailChimp,</li>
        <li>ActiveCampaign,</li>
        <li>Getresponse, </li>
        <li>ConvertKit, </li>
        <li>Lemlist, </li>
        <li>Snov.io, </li>
        <li>SendFox, </li>
        <li>Moosend, </li>
        <li>MailerLite, </li>
        <li>Automizy, </li>
        <li>Sendgrid, </li>
        <li>Sendinblue, </li>
        <li>Klaviyo,</li>
        <li>Drip,</li>
        <li>Hyros(MarketHero),</li>
        <li>Gist,</li>
        <li>Pabbly,</li>
        <li>GoHighLevel,</li>
        <li>EmailOctopus</li>
        <li>Omnisend</li>
        <li>BenchmarkEmail</li>
        <li>ClickFunnels</li>
        <li>Systeme.io</li>
        <li>Beehiiv</li>
        <li>HubSpot</li>
        <li>Platfmorm.ly</li>
      </TitleText>
      <TitleText textAlign="start">
        GroupKit has a total of 10 integrations, and Groupboss has direct
        integration with 25+ different email autoresponders.
      </TitleText>
    </GroupManageTitleContainer>

    <div style={{ backgroundColor: `#EAFAFA`, paddingBottom: `2rem` }}>
      <GroupManageTitleContainer>
        <TitleH3>
          5. Create FB Custom and Lookalike Audience Using Groupboss.{" "}
        </TitleH3>
        <TitleText textAlign="start">
          It is one of the unique features of Groupboss. In the Groupboss
          dashboard, when you click on{" "}
          <strong>‘Create FB custom and lookalike audiences’ </strong>then your
          data will be downloaded in a CSV file. After that, you can use that
          saved custom/lookalike audience list when you are going to generate
          Facebook ads.
        </TitleText>

        <ImageContainer>
          <img
            src={groupKit1}
            alt="Create FB Custom and Lookalike Audience"
            width="100%"
          />
        </ImageContainer>

        <TitleText textAlign="start">
          They have mentioned this feature on their website. But there is
          nothing mentioned about the process of getting the custom and
          lookalike audiences. And there is no specific tutorial for that as
          well.
        </TitleText>
      </GroupManageTitleContainer>
    </div>

    <GroupManageTitleContainer style={{ backgroundColor: `#FDFAF3` }}>
      <TitleH3>6. In-depth Tutorials</TitleH3>
      <TitleText textAlign="start">
        Groupboss is equipped with all necessary guidelines and tutorials to
        provide the best experience to the users. There are documentation on
        their{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://groupboss.io/help/"
        >
          support page
        </StyledA>{" "}
        as well as videos posted to{" "}
        <StyledA
          color="#007AFF"
          textDecoration="underline"
          target="_blank"
          href="https://www.youtube.com/c/Groupboss"
        >
          YouTube.
        </StyledA>{" "}
      </TitleText>
      <TitleText textAlign="start">for your convenience!</TitleText>

      <TitleText textAlign="start">
        These resources are great if you want an instructional guide about how
        to use Groupboss.
      </TitleText>

      <TitleText textAlign="start">
        The tutorials are in written and video format, with additional support
        available on their website and YouTube channel!
      </TitleText>

      <TitleText textAlign="start">
        On the other hand, GroupKit has a minimal number of tutorials for their
        users..
      </TitleText>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#FFF5F7` }}>
      <TitleH3>7. Intuitive Dashboard:</TitleH3>
      <TitleText textAlign="start">
        You can easily go to the dashboard from the Groupboss chrome extension.
        The UI of Groupboss is very clear. You can download data from the
        dashboard very easily with a single click. You can also see the graph of
        your data collection and see the leads collected in a date range.
      </TitleText>

      <ImageContainer>
        <img src={groupKit2} alt="Groupboss Dashboard" width="100%" />
      </ImageContainer>

      <TitleText textAlign="start">
        GroupKit has the dashboard option as well. But it doesn’t have many
        customizations like Groupboss.
      </TitleText>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer style={{ backgroundColor: `#EAFAFA` }}>
      <TitleH3>8. Getting Data of Definite Time Range:</TitleH3>
      <TitleText textAlign="start">
        Groupboss gives you the flexibility to see the data collected in a
        definite interval of time. You can also see the amount of data collected
        on a particular day or in a definite range of time. You can select the
        date ranges in the Groupboss dashboard.
      </TitleText>

      <ImageContainer>
        <img
          src={groupKit3}
          alt="Date ranges in the Groupboss dashboard."
          width="100%"
        />
      </ImageContainer>

      <TitleText textAlign="start">
        The new version of GroupKit has got this feature. You need to see all
        the data together.
      </TitleText>
    </GroupManageTitleContainer>

    <GroupManageTitleContainer>
      <GroupManageTitle>Groupkit short review</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        Groupkit helps you to collect leads from your Facebook group. You can
        also manage those leads nicely using Groupkit.
      </TitleText>

      <ColoredContainer isProsCons bgColor="#F8FCFC">
        <TitleH3 style={{ margin: `0 0 1rem` }}>Pros</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>Automated lead collection from the Facebook group</ListText>
          <ColoredDot />
          <ListText>Integrations with email marketing autoresponders</ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>Tag feature for better management of the leads</ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>Access to their private Facebook group</ListText>
        </CustomList>

        <TitleH3 style={{ margin: `1.5rem 0 1rem` }}>Cons</TitleH3>
        <CustomList>
          <ColoredDot bgColor="#5BEAC3" />
          <ListText>Pricing is high</ListText>
          <ColoredDot />
          <ListText>
            Very few support documents and tutorials to get started.{" "}
          </ListText>
          <ColoredDot bgColor="#33CBCF" />
          <ListText>
            Access to 1 Facebook group with a 300 contact limit in the monthly
            plan
          </ListText>
          <ColoredDot bgColor="#FD99B4" />
          <ListText>No chat support</ListText>
        </CustomList>
      </ColoredContainer>

      <GroupManageTitle>Last Words:</GroupManageTitle>
      <BottomLine />
      <TitleText textAlign="start">
        Team Groupboss always gives priority to the satisfaction of customers.
        It also values the customers’ suggestions and feedback to fulfil their
        demands.
      </TitleText>
      <TitleText textAlign="start">
        After getting all this information, it’s now your turn to decide which
        software is the best suit for you.
      </TitleText>
      <TitleText textAlign="start">
        Want to try the best ever GroupKit alternative?
      </TitleText>
      <div style={{ display: `grid`, justifyContent: `center` }}>
        <Link
          to="/pricing"
          target="_blank"
          style={{ textDecoration: "none", color: "#33475B" }}
        >
          <FreeTrialButton>Get Started with Groupbos</FreeTrialButton>
        </Link>
      </div>
      <TitleText textAlign="start">
        If you looking into other similar software and their alternatives, you
        may look at the following articles.
      </TitleText>
      <ul>
        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/grouptrackcrm-alternative-and-review/"
          >
            The Best GroupTrack CRM Alternative and Review.
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupfunnels-alternative/"
          >
            The Best GroupFunnels Alternative and Review
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupx-alternative/"
          >
            The Best groupX Alternative and Review.
          </StyledA>{" "}
        </StyledList>

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupconvert-alternative/"
          >
            The Best Groupconvert Alternatives and Review
          </StyledA>{" "}
        </StyledList>

        

        <StyledList>
          {" "}
          <StyledA
            color="#007AFF"
            target="_blank"
            href="https://groupboss.io/groupspro-alternative/"
          >
            The Best Groups Pro Alternative and Review.
          </StyledA>{" "}
        </StyledList>
      </ul>
    </GroupManageTitleContainer>
    <TestimonialIndex alternative="groupleads" />
    <CardContainer>
      <CommonCard
        cardTextWeight="500"
        cardText="24/7 Support by real people"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="100% Secure payment"
        cardPadding="13px 25px"
      />
      <CommonCard
        cardTextWeight="500"
        cardText="Best customer experience"
        cardPadding="13px 25px"
      />
    </CardContainer>
    <BgImgComp />
  </>
)

export const query = graphql`
  {
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    artwork_alternative: file(
      relativePath: { eq: "groupboss-groupx-artwork.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo: file(relativePath: { eq: "gb-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gk_logo: file(relativePath: { eq: "groupkit-logo.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gb_logo_mobile: file(relativePath: { eq: "gb-logo-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    gl_logo_mobile: file(relativePath: { eq: "gl-logo-2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    poor_customer: file(
      relativePath: { eq: "feedback-poor-customer-support.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux: file(
      relativePath: { eq: "feedback-better-user-experience.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux: file(relativePath: { eq: "feedback-clean-clear-UX.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    groupboss_poster: file(relativePath: { eq: "shot-1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials: file(
      relativePath: { eq: "feedback-detailed-tutorials.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    poor_customer_mobile: file(
      relativePath: { eq: "feedback-poor-customer-support-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    better_ux_mobile: file(
      relativePath: { eq: "feedback-better-user-experience-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    clear_ux_mobile: file(
      relativePath: { eq: "feedback-clean-clear-UX-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    detailed_tutorials_mobile: file(
      relativePath: { eq: "feedback-detailed-tutorials-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    tick: file(relativePath: { eq: "tick_2.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
    cross: file(relativePath: { eq: "cross.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 27
          placeholder: NONE
          layout: FIXED
        )
      }
    }
  }
`
